<ul class="navbar-nav mr-lg-auto">
  <li class="nav-item" [class.dropdown]="!item.root" *ngFor="let item of items">
    <ng-container *ngIf="!item.root">
      <a class="nav-link dropdown-toggle" href="#" [id]="item.url.replace('/', '-')" [class.active]="router.isActive(item.url, false)" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{ item.title }}
      </a>
      <ul class="dropdown-menu" [attr.aria-labelledby]="item.url.replace('/', '-')">
        <li *ngFor="let subitem of item.submenu">
          <a class="dropdown-item" *ngIf="isInUserRoles(subitem.roles?.toString())" [routerLink]="[subitem.url]" >
            {{ subitem.title }}
          </a>
        </li>
      </ul>
    </ng-container>
    <a *ngIf="item.root" class="nav-link" [routerLink]="[item.url]" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      {{ item.title }}
    </a>
  </li>
</ul>

