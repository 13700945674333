import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cep' })
export class CepPipe implements PipeTransform {
	transform(v: string): string {
		if (!v) {
			return v;
		}
		//Remove tudo o que não é dígito
		v = v.replace(/\D/g, "");

		//Coloca um ponto entre o segundo e o terceiro dígitos
		v = v.replace(/(\d{2})(\d)/, "$1.$2");

		//Coloca um hífen entre o quinto e o sexto dígitos
		v = v.replace(/(\d{3})(\d{1,3})$/, "$1-$2");

		return v;
	}
}