import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../utils/utils';

@Pipe({
    name: 'formatdate'
})

export class FormatDatePipe implements PipeTransform {

    transform(value: any): any {
        value = value || '0';
        
        value = value.replaceAll('.', '/');

        return value;
    }

}
