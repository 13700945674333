import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Message } from '../utils/utils';

@Injectable()

export class RoleGuard implements CanActivate {

    constructor(private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot) {
        let userRoles = this.authService.roles;
        let routeRoles: string[] = (route.data.roles instanceof Array) ? route.data.roles : route.data.roles ? [route.data.roles] : [];
        
        if (!routeRoles) return false;

        let hasRole = userRoles.some(role => routeRoles.indexOf(role) > -1);
        
        if (!hasRole) {
            Message.info('Acesso não permitido');
        }

        return hasRole;
    }
}