import { Directive, Input, TemplateRef, ViewContainerRef, ViewRef, ComponentRef, Output, EventEmitter, Renderer2, EmbeddedViewRef } from '@angular/core';
import { AuthService } from '../auth/auth.service';


declare var $;

@Directive({
    selector: '[isInRole],[isNotInRole]'
})
export class IsInRoleDirective {

    @Input()
    set isInRole(value: string[] | string) {
        this.isOrNotInRole(value, false);
    }

    @Input()
    set isNotInRole(value: string[] | string) {
        this.isOrNotInRole(value, true);
    }

    constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef, private authService: AuthService) { }

    private isOrNotInRole(value: string[] | string, inverse: boolean) {
        let userRoles = this.authService.roles;
        let roles: string[] = (value instanceof Array) ? value : value ? [value] : [];

        if (!roles) return;
        let hasRole = userRoles.some(r => roles.indexOf(r) > -1);
        if (hasRole !== inverse) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainerRef.clear();
        }

    }

}

/**
 *
 *<input [ngModel]="salario" disabled *isNotInRole="'role'"  />
<input ([ngModel])="salario" *isInRole="'role'" />
 */

@Directive({
    selector: '[disabledByRole]'
})
export class DisabledByRoleDirective {

    _childrenPropagate = false;
    @Input()
    set childrenPropagate(value: boolean) {
        this._childrenPropagate = value;
    }

    @Input()
    set disabled(value: boolean) { // usado para sobrescrever a diretiva "nativa" [disabled]
        //console.log(value);
    }

    private oldNode = null;

    @Input()
    set disabledByRole(value: string[] | string) {
        this.isOrNotInRole(value);
    }

    constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef, private renderer: Renderer2, private authService: AuthService) { }

    private isOrNotInRole(value: string[] | string) {
        this.viewContainerRef.clear();
        let root = this.viewContainerRef.createEmbeddedView(this.templateRef);

        if (this.oldNode) {
            this.oldNode.remove();
            this.oldNode = null;
        }

        let userRoles = this.authService.roles;
        let roles: string[] = (value instanceof Array) ? value : value ? [value] : [];
        if (!roles) return;
        let hasRole = userRoles.some(r => roles.indexOf(r) > -1);

        if (!hasRole) {
            let el = root.rootNodes[0];


            this.renderer.addClass(el, 'disabled');

            let tags = ['INPUT', 'BUTTON', 'SELECT', 'A'];
            if (tags.indexOf(el.tagName) !== -1) {
                this.disableElement(el);
            }

            if (this._childrenPropagate.toString() === 'true') {
                el.querySelectorAll('input, select, button, a').forEach(n => {
                    this.disableElement(n);
                });
            }
        }

    }

    private disableElement(el: HTMLElement) {
        this.renderer.setAttribute(el, 'disabled', 'disabled');
        if (el.tagName === 'A') {
            if (!el.parentNode) return;
            let clone = el.cloneNode();
            while (el.firstChild) {
                clone.appendChild(el.lastChild);
            }
            el.parentNode.replaceChild(clone, el);
            this.oldNode = clone;
        } else {
            el.removeEventListener('input', null);
            el.removeEventListener('click', null);
        }
    }

}

/*
import { ElementRef, Renderer, Renderer2, HostListener } from '@angular/core';

@Directive({
    selector: '[disabledByRole]',
    outputs: ['click']
})
export class DisabledByRoleDirective {

    @Input()
    childrenPropagate = false;

    @Input()
    set disabled(value: boolean) { // usado para sobrescrever a diretiva "nativa" [disabled]
        //console.log(value);
    }

    @Input()
    set disabledByRole(value: string[] | string) {
        let userRoles = this.authService.roles;
        console.log(userRoles);

        let roles: string[] = (value instanceof Array) ? value : [value];
        if (!roles) return;
        let hasRole = userRoles.some(r => roles.indexOf(r) > -1);

        console.log('veio na disabled by role');
        console.log(userRoles);

        if (!hasRole) {
            let el = this.elementRef.nativeElement;
            let tags = ['INPUT', 'BUTTON', 'SELECT', 'A'];
            if (tags.indexOf(el.tagName) !== -1) {
                this.disableElement(el);
            }

            if (this.childrenPropagate.toString() === 'true') {
                el.querySelectorAll('input, select, button, a').forEach(n => {
                    this.disableElement(n);
                });
            }
        }
    }

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private viewRef: ViewContainerRef, private authService: AuthService) { }

    @HostListener('click', ['$event'])
    click(event: MouseEvent) {
    }

    @Output('click') userClick = new EventEmitter();

    private disableElement(el: HTMLElement) {
        this.renderer.setAttribute(el, 'disabled', 'true');
        if (el.tagName === 'A') {
            if (!el.parentNode) return;
            let clone = el.cloneNode();
            while (el.firstChild) {
                clone.appendChild(el.lastChild);
            }
            el.parentNode.replaceChild(clone, el);
        } else {
            el.removeEventListener('input', null);
            el.removeEventListener('click', null);
        }
    }
}

*/
