import { Component, Input, OnInit, AfterViewInit, ElementRef, ContentChild, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Utils } from '../utils/utils';

//import { ValidationBlockComponent } from './validation-block.component';


declare let $;

@Component({
	selector: '[inputWrapper]',
	template: `
        <label for="{{ guid }}" [style]="sanitizer.bypassSecurityTrustStyle(labelStyle)"
            *ngIf="label">{{ label }}<span *ngIf="isRequired" class="mark-required"></span></label>
        <ng-content></ng-content>
        <validation-block [control]="ngControl" [inputEditing]="inputEditing"></validation-block>
    `,
	styles: [``]
})
export class InputWrapperComponent implements AfterViewInit, OnDestroy {
	@ContentChild(NgControl, { static: true }) ngControl: NgControl;
	@Input() label: string;
	@Input() labelStyle: string = '';

	hasFocus = false;
	inputEditing = false;

	$input;

	guid;

	constructor(private el: ElementRef, public sanitizer: DomSanitizer) { }

	get isRequired() {
		return (
			this.el.nativeElement
				.querySelector('input,select,textarea')
				.hasAttribute('required') ||
			this.el.nativeElement
				.querySelector('input,select,textarea')
				.hasAttribute('v-required')
		);
	}

	ngOnInit() {
		let el = this.el.nativeElement.querySelector('input,select,textarea');
		if (!el.hasAttribute('id')) {
			if (el.hasAttribute('name')) {
				this.guid = el.getAttribute('name');
			} else {
				this.guid = Utils.guid();
			}
			el.setAttribute('id', this.guid);
		} else {
			this.guid = el.getAttribute('id');
		}
	}

	ngOnDestroy() {
		if (this.$input) {
			this.$input.off('blur', this.onBlur).off('focus', this.onFocus);
			this.$input.remove();
			this.$input = null;
		}
		$(this.el.nativeElement).unbind('input', this.onInput);
		if (this.ngControl) {
			this.ngControl = null;
		}
	}

	onBlur = () => {
		this.inputEditing = false;
		this.hasFocus = false;
	};

	onFocus = () => {
		this.hasFocus = true;
	};

	onInput = () => {
		if (this.ngControl.control.errors && this.ngControl.control.errors['remote']) {
			delete this.ngControl.control.errors['remote'];
		}
		this.inputEditing = true;
		this.ngControl.control.markAsDirty();
	};

	ngAfterViewInit() {

		$(this.el.nativeElement).bind('input', this.onInput);

		this.$input = $(this.el.nativeElement)
			.find('input,select,textarea')
			.not('.datepicker-hidden');

		this.$input.on('blur', this.onBlur).on('focus', this.onFocus);

		if (!this.$input) {
			return;
		}


		this.ngControl.statusChanges.subscribe(() => {
			if ((this.ngControl.dirty) && (this.ngControl.invalid)) {
				this.$input.addClass('is-invalid')
			} else {
				this.$input.removeClass('is-invalid');
			}
		});

		this.$input.attr('autocomplete', 'off').addClass('form-control');
	}
}
