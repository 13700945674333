import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cnpjcpf' })
export class CnpjCpfPipe implements PipeTransform {
	transform(s: string): string {
		if (!s) {
			return s;
		}
		//Remove tudo o que não é dígito
		s = s.replace(/\D/g, "")

		if (s.length <= 12) { //CPF

			//Coloca um ponto entre o terceiro e o quarto dígitos
			s = s.replace(/(\d{3})(\d)/, "$1.$2")

			//Coloca um ponto entre o terceiro e o quarto dígitos
			//de novo (para o segundo bloco de números)
			s = s.replace(/(\d{3})(\d)/, "$1.$2")

			//Coloca um hífen entre o terceiro e o quarto dígitos
			s = s.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

		} else { //CNPJ
			//Coloca ponto entre o segundo e o terceiro dígitos
			s = s.replace(/^(\d{2})(\d)/, "$1.$2")

			//Coloca ponto entre o quinto e o sexto dígitos
			s = s.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")

			//Coloca uma barra entre o oitavo e o nono dígitos
			s = s.replace(/\.(\d{3})(\d)/, ".$1/$2")

			//Coloca um hífen depois do bloco de quatro dígitos
			s = s.replace(/(\d{4})(\d)/, "$1-$2")

		}
		return s
	}
}
