import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../utils/utils';

@Pipe({
    name: 'formatnumber'
})

export class FormatNumberPipe implements PipeTransform {

    transform(value: any, n = 2, showZero = true, clearZeroRight = false): any {
        value = value || '0';
        let v = parseFloat(value);
        let r = Utils.format(v, n || 2, 3, '.', ',');
        if ((!showZero) && (v === 0)) {
            r = '';
        } else if (clearZeroRight) {
            // implementar em data futura
            //r = Utils.removeZeroRight(r);
        }
        return r;
    }

}
