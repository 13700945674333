import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[fixedHeight]'
})
export class FixedHeightDirective {

    private _offset = 86;

    el: HTMLElement;

    @Input('fixedHeight')
    set offset(value) {
        if (value === -1) {
            return;
        } else if (value) {
            this._offset = parseInt(value);
        }

        this.el = this.elementRef.nativeElement;
        this.el.style.overflowY = 'auto';
        window.addEventListener('resize', () => {
            this.resize();
        });

        /*
        this.$el = $(this.elementRef.nativeElement);
        this.$el.css('overflow-y', 'auto'); // scroll exibe sempre, auto só exibe qdo necessário
        $(window).on('resize', () => {
            this.resize();
        });

        */

        this.resize();
    }

    constructor(private elementRef: ElementRef) { }

    resize() {
        const height = window.innerHeight - this._offset;
        this.el.style.height = height + 'px';

        //this.$el.css('height', height + 'px');
    }

}

@Directive({
    selector: '[minHeight]'
})

export class MinHeightDirective {

    private _offset = 86;

    el: HTMLElement;

    constructor(private elementRef: ElementRef) { }


    @Input('minHeight')
    set offset(value) {
        if (value === -1) {
            return;
        } else if (value) {
            this._offset = parseInt(value);
        }

        this.el = this.elementRef.nativeElement;
        this.el.style.overflowX = 'none';
        window.addEventListener('resize', () => {
            this.resize();
        });

        /*
        this.$el = $(this.elementRef.nativeElement);
        this.$el.css('overflow-x', 'none');
        $(window).on('resize', () => {
            this.resize();
        });
        */

        this.resize();
    }

    resize() {
        const height = window.innerHeight - this._offset;

        //this.$el.css('min-height', height + 'px');

        this.el.style.minHeight = height + 'px';

    }

}

interface IFixedHeightConfig {
    lg?: number;
    md?: number;
    sm?: number;
    xs?: number;
}
