import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './layout/home.component';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './shared/auth/auth.guard';

const routes: Routes = [
    //{ path: '', component: , canActivate: [AuthGuard] }
    {
        path: '', component: LayoutComponent, canActivate: [AuthGuard], children: [
            { path: '', component: HomeComponent },
            { path: 'estoque', loadChildren: () => import('./content/estoque/estoque.module').then(m => m.EstoqueModule) },
            { path: 'financeiro', loadChildren: () => import('./content/financeiro/financeiro.module').then(m => m.FinanceiroModule) },
            { path: 'clientes', loadChildren: () => import('./content/clientes/clientes.module').then(m => m.ClientesModule) },
            { path: 'outros', loadChildren: () => import('./content/outros/outros.module').then(m => m.OutrosModule) },
            { path: 'redes', loadChildren: () => import('./content/redes/redes.module').then(m => m.RedesModule) },
            { path: 'relatorio', loadChildren: () => import('./content/relatorio/relatorio.module').then(m => m.RelatorioModule) },
            { path: 'tecnica', loadChildren: () => import('./content/tecnica/tecnica.module').then(m => m.TecnicaModule) }
    ] },
    { path: 'login', loadChildren: () => import('./content/login/login.module').then(m => m.LoginModule) }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
