import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
    selector: 'td [colspanAll]'
})
export class ColspanAllDirective implements OnInit {

    constructor(private elRef: ElementRef) { }

    ngOnInit() {
        let el = this.elRef.nativeElement as HTMLTableDataCellElement;
        let tr = el.closest('table').querySelector('tr');
        el.colSpan = tr.cells.length;
    }

}