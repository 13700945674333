import { Directive, ElementRef, HostListener, Input, AfterViewInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Utils } from '../utils/utils';
import { fromEvent as observableFromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

declare var $;

@Directive({
    selector: '[inputNumber]'
})
export class InputNumberDirective implements AfterViewInit {

    private onChangeCallback: (_: any) => void = (_) => { };
    private onTouchedCallback: () => void = () => { };

    @Input() decimals = 2;
    @Input() allowNegative = false;

    @Input() symbol = 'R$';
    @Input() showSymbol = false;

    @Input() shouldFormat = true;

    constructor(private el: ElementRef, private ngModel: NgModel) {
        this.ngModel.valueAccessor.registerOnChange = this.registerOnChange;
        this.ngModel.valueAccessor.registerOnTouched = this.registerOnTouched;
        this.ngModel.valueAccessor.writeValue = this.writeValue;
    }

    ngAfterViewInit() {
        observableFromEvent(this.el.nativeElement, 'input')
            .pipe(map((n: any) => n.target.value))
            .subscribe(n => {
                if (n.toString().length > 0) {
                    let v = parseFloat(n.toString().replace(',', '.'));
                    this.onChangeCallback(v);
                } else {
                    this.onChangeCallback(null);
                }
            });

        if (this.showSymbol && this.symbol !== '') {
            let $el = $(this.el.nativeElement);
            let $symbol = $('<span class="input-group-addon">' + this.symbol + '</span>');
            $el.wrap('<div class="input-group left-addon">').before($symbol);;
        }

    }

    @HostListener('focus', ['$event'])
    myFocus(e) {
        let value =  $(this.el.nativeElement).val().replace(/\./g, '');
        this.el.nativeElement.value = value;
        $(this.el.nativeElement).select();
    }

    @HostListener('blur', ['$event'])
    myBlur(e) {
        let value: any = parseFloat($(this.el.nativeElement).val().replace(/\./g, '').replace(',', '.'));
        if (isNaN(value)) {
            value = '';
        } else {
            this.el.nativeElement.value = this.format(value, this.decimals, 3, '.', ',');
        }
    }

    @HostListener('keypress', ['$event'])
    myKeyPressHandler(e) {

        let el = this.el.nativeElement;
        let $el = $(el);

        var key = e.keyCode || e.which;

        var decimalLength = this.decimals;

        var allow = false;

        // Se  for uma tecla númerica
        if (key >= 48 && key <= 57) {

            var posDecimal = $(el).val().indexOf(',');

            // Verifica se já existe ponto de marcação decimal
            if (posDecimal != -1) {
                var start = el.selectionStart;
                var end = el.selectionEnd;
                var decimalContent = $(el).val().substring(posDecimal + 1, $(el).val().length);
                if (posDecimal < start) {
                    if (decimalContent.length >= decimalLength) {
                        return false;
                    }
                }
            }
            allow = true;
        }
        else if (this.allowNegative && key == 45 && el.selectionStart == '0') {
            allow = true;
        }
        // Ponto ou virgula
        else if (key == 44 || key == 46) {

            var value = $(el).val();

            // Verifica se o conteúdo já possui marcação de decimal
            if (value.indexOf(",") != -1) {
                return false;
            } else if (el.selectionStart || el.selectionStart == '0') {

                var start = el.selectionStart;
                var end = el.selectionEnd;

                // Se o fragmento de dados da posição do cursor até o final for superior ao número de casas
                // decimais não aceita a entrada
                if ($(el).val().substring(end, $(el).val().length).length > decimalLength) {
                    return false;
                }

                // Substitui o conteúdo da seleção pela marcação decimal
                $(el).val($(el).val().substring(0, start) + ','
                + $(el).val().substring(end, $(el).val().length));

                // Seta a posição do cursor para o início da seleção mais uma posição
                el.selectionStart = start + 1;
                el.selectionEnd = start + 1;

            } else {
                // Se não foi possível determinar seleção coloca a marcação
                // decimal no final do texto
                $(el).val($(el).val() + ',');
            }
            return false;
        } else {
            allow = false;
        }

        return allow;
    }

    registerOnChange = (fn: (_: any) => void): void => { this.onChangeCallback = fn; };
    registerOnTouched = (fn: () => void): void => { this.onTouchedCallback = fn; };

    writeValue = (value: any) => { // do model pro input
        if (value) {
            this.el.nativeElement.value = this.format(value, this.decimals, 3, '.', ',');
        } else {
            this.el.nativeElement.value = '';
        }
    }

    private format(value, numDecimals, numThousands, decimalSeparator, thousandsSeparator) {
        if (this.shouldFormat.toString() === 'true') {
            return Utils.format(parseFloat(value), numDecimals, numThousands, decimalSeparator, thousandsSeparator);
        } else {
            return value;
        }
    }

}
