import { NgControl } from '@angular/forms';
import { Component, Input, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';

declare let $;

@Component({
    selector: 'validation-block',
    template: `
<div *ngIf="(!inputEditing && control && !control?.valid && control?.dirty)">
    <span *ngIf="control.errors?.required">Requerido</span>
    <span *ngIf="!control.errors?.required && control.errors?.minlength">Mínimo {{control.errors.minlength.requiredLength}} caracteres</span>
    <span *ngIf="!control.errors?.required && control.errors?.maxlength">Máximo {{control.errors.maxlength.requiredLength}} caracteres</span>
    <span *ngIf="!control.errors?.required && control.errors?.cnpjCpf">CNPJ ou CPF inválido</span>
    <span *ngIf="!control.errors?.required && control.errors?.cpf">CPF inválido</span>
    <span *ngIf="!control.errors?.required && control.errors?.cnpj">CNPJ inválido</span>
    <span *ngIf="!control.errors?.required && control.errors?.telefone">Número de telefone inválido</span>
    <span *ngIf="!control.errors?.required && control.errors?.email">E-mail inválido</span>
    <span *ngIf="!control.errors?.required && control.errors?.max">Máximo {{control.errors.max.value}}</span>
    <span *ngIf="!control.errors?.required && control.errors?.min">Mínimo {{control.errors.min.value}}</span>
    <span *ngIf="!control.errors?.required && control.errors?.autocomplete">{{control.errors.autocomplete}}</span>
    <span *ngIf="control.errors?.remote">{{control.errors.remote}}</span>
    <div style="overflow: auto; margin-bottom: -3px;"></div>
</div>
`,
    styles: [`
div {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}`]
})
export class ValidationBlockComponent implements AfterViewInit, OnDestroy {

    @Input() control: any;
    @Input() editing;
    @Input() inputEditing;
    @Input() controlEditing: boolean = false;

    constructor(private el: ElementRef) { }

    private $input;

    onInput = () => {
        if (this.control.control.errors && this.control.control.errors['remote']) {
            delete this.control.control.errors['remote'];
        }
        this.inputEditing = true;
        this.control.control.markAsDirty();
    };

    onBlur = () => {
        this.inputEditing = false;
    };

    ngAfterViewInit() {

        if (!this.controlEditing) {
            return;
        }

        this.$input = $(this.el.nativeElement.parentNode).find('[name=' + this.control.name + ']');

        $(this.$input).bind('input', this.onInput);

        $(this.$input).bind('blur', this.onBlur);
    }

    ngOnDestroy() {
        if (this.$input) {
            this.$input.unbind('input', this.onInput).unbind('blur', this.onBlur);
            this.$input.remove();
            this.$input = null;
        }
        if (this.control) {
            this.control = null;
        }
    }
}