import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()

export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let r = false;
        let returnUrl = state.url === '/' ? '' : state.url;

        if (this.authService.isAuthenticated) {
            r = true;
        } else {
            this.authService.redirectToLogin(returnUrl);
        }
                
        return r;
    }
}