import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicMenuConfig, DynamicMenuConfigType } from 'src/app/config';
import { AuthService } from 'src/app/shared/auth/auth.service';

@Component({
    selector: 'dynamic-menu',
    templateUrl: 'dynamic-menu.component.html'
})

export class DynamicMenuComponent implements OnInit {

    items: DynamicMenuConfigType[] = [];
    userRoles: string[] = [];

    constructor(public router: Router, private authService: AuthService) { }

    ngOnInit() {
        this.items = DynamicMenuConfig.items as DynamicMenuConfigType[];
        this.userRoles = this.authService.roles;
        // console.log(this.userRoles);
    }

    isInUserRoles(role){
        // console.log("UserRoles: ", this.userRoles);
        // console.log("Role: ", role);
        // console.log("retorno: ", this.userRoles.indexOf(role));
        // console.log(role);
        if(this.userRoles.indexOf(role) >= 0 || role == null)
            return true;
        else
            return false;
    }
}
