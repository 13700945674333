import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { config } from 'src/app/config';



@Injectable()
export class AuthService {
    public get token(): string {
        return sessionStorage.getItem(config.tokenKey);
    }

    public get roles(): string[] {
        let roles: string | string[] = [];
        let stringRoles = sessionStorage.getItem(config.rolesKey);
        if (stringRoles !== "undefined") {
            roles = JSON.parse(sessionStorage.getItem(config.rolesKey));
        }
        return (roles instanceof Array) ? roles : (roles) ? [roles] : [];
    }

    public get user(): IUser {
        let user: IUser;
        let stringUser = sessionStorage.getItem(config.userKey);
        if (stringUser !== "undefined") {
            user = JSON.parse(sessionStorage.getItem(config.userKey));
        }
        return user;
    }

    constructor (private jwtHelper: JwtHelperService, private router: Router) { }

    public login(token: string, user: IUser, returnUrl?: string, tokenClaims?: string): boolean {
        let r = false;
        if (token && token.split('.').length === 3) {
            let d = this.jwtHelper.decodeToken(token);
            sessionStorage.setItem(config.tokenKey, token);
            r = true;
        }

        if (r && tokenClaims && tokenClaims.split('.').length === 3) {
            let d = this.jwtHelper.decodeToken(tokenClaims);
            let roles: string[] = d['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            if (roles) {
                sessionStorage.setItem(config.rolesKey, JSON.stringify(roles));
            }
        }

        if (r && user) {
            sessionStorage.setItem(config.userKey, JSON.stringify(user));
        }

        if (r && returnUrl) {
            this.router.navigateByUrl(returnUrl);
        } else {
            this.router.navigate(['/']);
        }

        return r;
    }

    public logout() {
        sessionStorage.removeItem(config.tokenKey);
        sessionStorage.removeItem(config.rolesKey);
        sessionStorage.removeItem(config.userKey);
    }

    public redirectToLogin(returnUrl?: string) {
        let s = 'returnUrl=';
        if (returnUrl && returnUrl.indexOf(s) > 0) {
            let r = returnUrl.split(s);
            returnUrl = r[r.length + 1];
        }

        if (returnUrl && returnUrl.indexOf('login') > -1) {
            returnUrl = '';
        }

        let routeUrl = '/login';
        if (returnUrl) {
            this.router.navigate([routeUrl], { queryParams: { returnUrl: returnUrl } });
        } else {
            this.router.navigate([routeUrl]);
        }
    }

    public get isAuthenticated(): boolean {
        // get the token
        const token = this.token;
        // return a boolean reflecting

        let r = token !== null;

        // whether or not the token is expired

        if (r) {
            r = !this.jwtHelper.isTokenExpired(token);
        }


        //console.log(token);
        return r;
    }
}

export interface IUser {
    username: string;
    name: string;
    regionalId: number;
    empresaId: number;
    empresa: string;
}
