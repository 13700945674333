import { Directive, ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { createLogicalAnd } from 'typescript';

declare var $;

@Directive({
    selector: 'select[select2]'
})
export class Select2Directive implements OnInit {

    constructor(private el: ElementRef, private model: NgModel) { }

    ngOnInit() {
        this.init(this.el.nativeElement);

        this.model.valueChanges.subscribe(n => {
            $(this.el.nativeElement).val(n).trigger('change');
        });

    }

    private updateFromEvent(ev) {
        let v = $(ev.currentTarget).val();
        // let n = null;
        // if (!isNaN(v)) {
        //     n = parseInt(v);
        // }
        this.updateModel(v);
    }

    private updateModel(v) {
        this.model.viewToModelUpdate(v);
        this.model.control.updateValueAndValidity({ emitEvent: false });
    }


    init(select) {
        var elementOptions = select.dataset.options ? JSON.parse(select.dataset.options) : {};

        var defaultOptions = {
            containerCssClass: select.getAttribute('class'),
            dropdownAutoWidth: true,
            dropdownCssClass: select.classList.contains('custom-select-sm') || select.classList.contains('form-control-sm') ? 'dropdown-menu dropdown-menu-sm show' : 'dropdown-menu show'

            //templateResult: this.formatTemplate
        };

        var options = Object.assign(defaultOptions, elementOptions);

        // Init
        $(select).select2(options);

        $(select).on('select2:select', (e) => {
            this.updateFromEvent(e);
        });
    }

}

