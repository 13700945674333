import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
    selector: '[scrollIntoView]'
})
export class ScrollIntoViewDirective implements OnInit {

    @Input('scrollIntoView') extra = 0;

    constructor(private elRef: ElementRef) { }

    ngOnInit() {
        let el = this.elRef.nativeElement as HTMLElement;
        
        el.scrollIntoView({ block: 'nearest', inline: 'center', behavior: 'smooth' });
        
        if (this.extra > 0) {
            window.scrollBy({ behavior: 'smooth', top: el.offsetTop + el.offsetHeight + this.extra });
        }
    }

}