import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Message, Utils } from 'src/app/shared/utils/utils';
import { AuthService, IUser } from 'src/app/shared/auth/auth.service';
import { Md5 } from 'ts-md5';

@Component({
    selector: 'app-topnav',
    templateUrl: './topnav.component.html',
    styleUrls: []
})
export class TopnavComponent implements OnInit {
    version: string = "";
    empresa: string = "";
    
    user: IUser;
    iconTheme = '';
    labelChangeTheme = '';

    constructor(private http: HttpClient, private auth: AuthService) { }

    ngOnInit() {
        this.user = this.auth.user;
        this.version = sessionStorage.getItem("version") != null ? sessionStorage.getItem("version") : localStorage.getItem("version");
        this.empresa = JSON.parse(sessionStorage?.getItem("app_user")) != null ? JSON.parse(sessionStorage?.getItem("app_user")).empresaNome.split(' ')[0] : "";
        
        // console.log("navigator.userAgent -> ", navigator.userAgent);
        // console.log("é mobile? ", navigator.userAgent.search("Mobile"));

        // console.log(sessionStorage);

        if(sessionStorage?.getItem("app_user") != null && JSON.parse(sessionStorage?.getItem("app_user")).tema_sistema == 1){
            this.setTheme('dark');
            // console.log("aqui");
        }
        else
        {
            this.setTheme('light');
        }

        // if(navigator.userAgent.search("Mobile") > 0){
        //     // console.log("É UM DISPOSITIVO MOBILE!");
        //     this.setTheme('light');
        // }
        // else
        // {
        //     this.setTheme('dark');
        // }
    }

    logout() {
        this.auth.logout();
        this.auth.redirectToLogin();
    }

    alternateTheme(){
        if (localStorage.getItem('theme') == 'light') {
            this.salvaTema('dark');
        } else {
            this.salvaTema('light');
        }
    }

    async salvaTema(theme){
        // console.log("chamou");
        var raw = {
            "username": this.user.username,
            "k": Md5.hashAsciiStr("hash do tema: " + this.user.username + this.version).toUpperCase(),
            "db": this.version,
            "tema_sistema": theme == 'dark' ? 1 : 0
        };

        await this.http.post<any>('AtualizaTema', raw).subscribe(n => {
            if (!n.success) {
                Message.info(n.message);   
            }
        }, () => {
            Message.error('Não foi possível conectar');
        });

        var app_user =  JSON.parse(sessionStorage?.getItem("app_user"));
        app_user.tema_sistema = (theme == 'dark') ? 1 : 0;
        // console.log(JSON.stringify(app_user));
        sessionStorage.setItem('app_user', JSON.stringify(app_user));

        this.setTheme(theme);
    }

    setTheme(theme) {
        if (theme == 'light') {
            // document.getElementById('csstheme').setAttribute('href', 'assets/css/theme-dark.min.css');
            document.getElementById('csstheme').setAttribute('href', 'assets/css/theme.min.css');
            document.getElementById('cssthemecustom').setAttribute('href', 'assets/css/theme.css');
            this.iconTheme = 'moon';
            this.labelChangeTheme = 'Modo Escuro';
        } else {
            // document.getElementById('csstheme').setAttribute('href', 'assets/css/theme.min.css');
            document.getElementById('csstheme').setAttribute('href', 'assets/css/theme-dark.min.css');
            document.getElementById('cssthemecustom').setAttribute('href', 'assets/css/theme-dark.css');
            this.iconTheme = 'sun';
            this.labelChangeTheme = 'Modo Claro';
        }
        localStorage.setItem('theme', theme);
    }
}
