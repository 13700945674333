import { ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';

declare var $;

declare var toastr;

export interface IMessageOptions {
    closeButton?: boolean;
    debug?: boolean;
    positionClass?: 'toast-top-right' | 'toast-bottom-right' | 'toastr-top-left' | 'toast-bottom-left' | 'toastr-top-center' | 'toast-bottom-center' | 'toast-top-full-width' | 'toast-bottom-full-width';
    newestOnTop?: boolean;
    progressBar?: boolean;
    preventDuplicates?: boolean;
    onclick?: () => void;
    showDuration?: number;
    hideDuration?: number;
    timeOut?: number;
    extendedTimeOut?: number;
    showEasing?: string;
    hideEasing?: string;
    showMethod?: string;
    hideMethod?: string;
    toastClass?: string;
}


export class Message {
    private static defaultOptions: IMessageOptions = { positionClass: 'toast-top-right', toastClass: 'toastr', closeButton: true };

    static error(message: string, title?: string, options?: IMessageOptions) {
        this.execute('error', message, title);
    }

    static warning(message: string, title?: string, options?: IMessageOptions) {
        this.execute('warning', message, title);
    }

    static info(message: string, title?: string, options?: IMessageOptions) {
        this.execute('info', message, title);
    }

    static success(message: string, title?: string, options?: IMessageOptions) {
        this.execute('success', message, title);
    }

    private static execute(command: string, message: string, title?: string, options?: IMessageOptions) {
        toastr.options = Object.assign(this.defaultOptions, options);
        toastr[command](message, title);
    }
}

export class Utils {

    static DECIMAL_ROUND = [10, 100, 1000, 10000, 100000, 1000000, 10000000, 100000000];

    static clone<T>(obj: T): T {
        return $.extend(true, {}, obj);
    }

    static findNestedObj(entireObj: any, keyToFind: string, valToFind: string) {
        let foundObj: any;
        JSON.stringify(entireObj, (_, nestedValue) => {
            if (nestedValue && nestedValue[keyToFind] === valToFind) {
                foundObj = nestedValue;
            }
            return nestedValue;
        });
        return foundObj;
    }

    static groupBy<T>(list: T[], keyOf: any): Map<any, T[]> {
        const map = new Map();
        list.forEach(item => {
            const key = keyOf(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    /** Resolve a propriedade de um objeto com vários níveis a partir de uma string (similar ao eval)
    **/
    static resolve(path: string, obj: any) {
        return path.split('.').reduce((prev, curr) => {
            return prev ? prev[curr] : undefined
        }, obj || self);
    }

    static async asyncForEach<T>(array: T[], callback: any) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    static orderBy<T>(list: T[], keyOf: (item: T) => any | string): T[] {
        return this.internalOrderBy(list, keyOf, false);
    }

    static orderByDescending<T>(list: T[], keyOf: (item: T) => any | string): T[] {
        return this.internalOrderBy(list, keyOf, true);
    }

    private static internalOrderBy<T>(list: T[], keyOf: (item: T) => any | string, reverse: boolean): T[] {
        let result = list.slice(0);
        result = list.sort((a, b) => {
            let ka;
            let kb;
            if (typeof keyOf === 'string') {
                ka = this.resolve(keyOf, a) || '';
                kb = this.resolve(keyOf, b) || '';
            } else {
                ka = keyOf(a) == null ? '' : keyOf(a);
                kb = keyOf(b) == null ? '' : keyOf(b);
            }
            return (ka > kb ? 1 : ka < kb ? -1 : 0) * [1, -1][+!!reverse];
        });
        return result;



    }

    static selectDistinct<T>(list: T[], callbackFn: (item: T) => any): T[] {
        return [...new Set<T>(list.map(callbackFn))];
    }

    static somenteNumeros(s: string) {
        let r = '';
        for (let i = 0; i < s.length; i++) {
            let x = s.charAt(i);
            if (!isNaN(parseInt(x))) {
                r += x;
            }
        }
        return r;
    }

    static copyToClipbard(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    /**Gera uma string guid única */
    static guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    /**Formata um numero.
    @param value Valor.
    @param numDecimals Número de decimais. Default 2
    @param numThousands Número de milhares. Default 3
    @param decimalSeparator Separador de decimais. Default .
    @param thousandsSeparator Separador de milhar. Default ,
    * */
    static format(value, numDecimals?, numThousands?, decimalSeparator?, thousandsSeparator?) { // valor, numero fracionarios, numero decimais, separador de decimais, separador de milhar
        let re = '\\d(?=(\\d{' + (numThousands || 3) + '})+' + (numDecimals > 0 ? '\\D' : '$') + ')',
            num = value.toFixed(Math.max(0, ~~numDecimals));
        return (thousandsSeparator ? num.replace('.', thousandsSeparator) : num).replace(new RegExp(re, 'g'), '$&' + (decimalSeparator || ','));
    }

    static numberFormat(value: any, decimals: number) {
        let n = parseFloat(value);
        return this.format(n, decimals, 3, ".", ",");
    }


    static round(value: number, decPlaces: number) {
        return Math.round(value * this.DECIMAL_ROUND[decPlaces - 1]) / this.DECIMAL_ROUND[decPlaces - 1];
    }

    static pad(n, width, z?) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    static removeAcentos(s: string) {
        if (!s) { return ''; }
        let accentMap = {
            'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'ã': 'a', 'õ': 'o', 'â': 'a', 'ê': 'e', 'î': 'i', 'ô': 'o', 'û': 'u', 'ç': 'c', 'ä': 'a', 'ë': 'e', 'ï': 'i', 'ö': 'o', 'ü': 'u', 'à': 'a', 'è': 'e', 'ì': 'i', 'ò': 'o', 'ù': 'u',
            'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U', 'Ã': 'A', 'Õ': 'O', 'Â': 'A', 'Ê': 'E', 'Î': 'I', 'Ô': 'O', 'Û': 'U', 'Ç': 'C', 'Ä': 'A', 'Ë': 'E', 'Ï': 'I', 'Ö': 'O', 'Ü': 'U', 'À': 'A', 'È': 'E', 'Ì': 'I', 'Ò': 'O', 'Ù': 'U'
        };
        let r = '';
        for (let i = 0; i < s.length; i++) {
            r += accentMap[s.charAt(i)] || s.charAt(i);
        }
        return r;
    }

    static getQueryStringParameterByName(name: string) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
            results = regex.exec(location.search);
        return results == null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    static toCamelCase(s: string) {
        return s.replace(/^([A-Z])|\s(\w)/g, (match, p1, p2, offset) => {
            if (p2) return p2.toUpperCase();
            return p1.toLowerCase();
        });
    }

    static toPascalCase(s: string) {
        return s.replace(/^./, (s1) => s1.toUpperCase());
    }

    static validateEmail(s) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(s);
    }

    static validateCnpj(s) {
        if (!s) return false;
        var i;
        var l = '';
        for (i = 0; i < s.length; i++) if (!isNaN(s.charAt(i))) l += s.charAt(i);
        s = l;
        if (s.length != 14) return false;
        var c = s.substr(0, 12);
        var dv = s.substr(12, 2);
        var d1 = 0;
        for (i = 0; i < 12; i++) d1 += c.charAt(11 - i) * (2 + (i % 8));
        if (d1 == 0) return false;
        d1 = 11 - (d1 % 11);
        if (d1 > 9) d1 = 0;
        if (dv.charAt(0) != d1) return false;
        d1 *= 2;
        for (i = 0; i < 12; i++) d1 += c.charAt(11 - i) * (2 + ((i + 1) % 8));
        d1 = 11 - (d1 % 11);
        if (d1 > 9) d1 = 0;
        if (dv.charAt(1) != d1) return false;
        return true;
    }

    static validateCpf(s) {
        if (!s) return false;
        var i;
        var l = '';
        for (i = 0; i < s.length; i++) if (!isNaN(s.charAt(i))) l += s.charAt(i);
        s = l;
        if (s.length != 11) return false;
        var c = s.substr(0, 9);
        var dv = s.substr(9, 2);
        var d1 = 0;
        for (i = 0; i < 9; i++) d1 += c.charAt(i) * (10 - i);
        if (d1 == 0) return false;
        d1 = 11 - (d1 % 11);
        if (d1 > 9) d1 = 0;
        if (dv.charAt(0) != d1) return false;
        d1 *= 2;
        for (i = 0; i < 9; i++) {
            d1 += c.charAt(i) * (11 - i);
        }
        d1 = 11 - (d1 % 11);
        if (d1 > 9) d1 = 0;
        if (dv.charAt(1) != d1) return false;
        return true;
    }

    static validateTelefone(s) {
        if (s == null) {
            return true;
        }
        s = this.somenteNumeros(s);
        if ((s.length == 10) || (s.length == 11)) {
            return true;
        } else {
            return false;
        }
    }

    static mask(m, v) {
        var m, l = (m = m.split('')).length, s = v.split(''), j = 0, h = '';
        for (var i = -1; ++i < l;)
            if (m[i] != '#') {
                if (m[i] == '\\' && (h += m[++i])) continue;
                h += m[i];
                i + 1 == l && (s[j - 1] += h, h = '');
            }
            else {
                if (!s[j] && !(h = '')) break;
                (s[j] = h + s[j++]) && (h = '');
            }
        return s.join('') + h;
    }

    static unflat(obj: any, key: string | string[], c: string = '_', ignoreNulls = true) {
        let ret = this.clone(obj);
        let keys: string[] = (key instanceof Array) ? key : key ? [key] : [];
        for (let s in ret) {
            keys.forEach(k => {
                let r = k + c;
                let i = s.split(r);
                if (i.length > 1) {
                    if (!ignoreNulls) {
                        if (!ret[k]) {
                            ret[k] = {};
                        }
                        ret[k][i[1]] = ret[s];
                    } else {
                        if (!ret[k] && ret[s] !== null) {
                            ret[k] = {};
                        }
                        if (ret[s] !== null) {
                            ret[k][i[1]] = ret[s];
                        }
                    }
                    delete ret[s];
                }
            });
        }
        return ret;
    }

    static unflatArray(a: any[], key: string | string[], c: string = '_', ignoreNulls = true) {
        let r = [];
        a.forEach(n => {
            r.push(this.unflat(n, key, c, ignoreNulls));
        });
        return r;
    }

    static getObjects(obj, key, val, par?) {
        var objects = [];
        for (var i in obj) {
            if (!obj.hasOwnProperty(i)) continue;
            if (typeof obj[i] == 'object') {
                objects = objects.concat(this.getObjects(obj[i], key, val, obj));
            } else
                //if key matches and value matches or if key matches and value is not passed (eliminating the case where key matches but passed value does not)
                if (i == key && obj[i] == val || i == key && val == '') { //
                    //objects.push(obj);
                } else if (obj[i] == val && key == '') {
                    //only add if the object is not already in the array
                    if (objects.lastIndexOf(obj) == -1) {
                        objects.push(par);
                    }
                }
        }
        return objects;
    }


    static downloadContent(content: any, fileName: string, type: string) {

        let textFileAsBlob = new Blob([content], { type: type });

        let downloadLink = document.createElement("a");
        downloadLink.download = fileName;
        downloadLink.innerHTML = "Download de arquivo";
        downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);

        downloadLink.click();

    }

    static openWindowContent(content: any, type: string, fileName?: string) {
        let name = fileName
        let mediaType = type;
        let blob = new Blob([content], { type: type });
        let url = window.URL.createObjectURL(blob);
        window.open(url);
        // this.popupBlockerDetection(url);
    }

    static sum(data: any[], field: string): number {
		if (!data) {
			return 0;
		}
		return data.map(n => Utils.resolve(field, n)).reduce((a, b) => Utils.nanToZero(a) + Utils.nanToZero(b), 0);
	}

	static nanToZero(value) {
		if (!value) {
			return 0;
		} else {
			return value;
		}
	}

    static popupBlockerDetection(poppedWindow) {

    }

    static getFileNameFromResponse(response) {
        let result = null;
        response.headers._headers.forEach((value, key, mapObj) => {
            if (key === 'content-disposition') {
                result = String(value).split(';')[1].trim().split('=')[1];
                result = result.replace(/"/g, '');
            }
        });
        return result;
    }

}

export class FormUtils {

    static validateForm(form: NgForm, el: ElementRef): boolean {
        let r = true;
        for (let s in form.controls) {
            let c = form.controls[s];

            if (c.invalid) {
                this.focusElement(el, s, 0);
                r = false;
                break;
            }
        }
        if (!r) {
            for (let s in form.controls) {
                let c = form.controls[s];
                c.markAsDirty();
            }
        }
        return r;
    }

    static focusElement(elContainer: ElementRef, elementNameOrId: string, timeout = 50) {
        let $c = $(elContainer.nativeElement).find('[name=' + elementNameOrId + '], #' + elementNameOrId);
        $c.focus();
        setTimeout(() => $c.select(), timeout);
    }

    //Não testada
    static searchElement(elContainer: ElementRef, elementNameOrId: string) {
        let $c = $(elContainer.nativeElement).find('[name=' + elementNameOrId + '], #' + elementNameOrId);
        return $c;
    }

    static modalOpen(elContainer: ElementRef, idModal) {
        $(elContainer.nativeElement).find(idModal).modal('show');
        $('html').css('overflow-y', 'hidden');
    }

    static modalClose(elContainer: ElementRef, idModal) {
        $(elContainer.nativeElement).find(idModal).modal('hide');
        $('html').css('overflow-y', 'initial');
    }
}

export interface ICrudOptions {
    urlInsert: string,
    urlUpdate: string,
    urlDelete: string,
    form?: NgForm,
    elementContainer?: ElementRef
}

export interface IRestResult {
    success: boolean,
    data?: any,
    errors?: any
}

export class XmlToJson {

	constructor(xml) {
		if (xml) {
			return this.parse(xml);
		}
	}

	/**
     * Adds an object value to a parent object
     *
     * @method addToParent
     * @param {Object} parent
     * @param {String} nodeName
     * @param {Mixed} obj
     * @return none
     */
	addToParent(parent, nodeName, obj) {
		// If this is the first or only instance of the node name, assign it as
		// an object on the parent.
		if (!parent[nodeName]) {
			parent[nodeName] = obj;
		}
		// Else the parent knows about other nodes of the same name
		else {
			// If the parent has a property with the node name, but it is not an array,
			// store the contents of that property, convert the property to an array, and
			// assign what was formerly an object on the parent to the first member of the
			// array
			if (!Array.isArray(parent[nodeName])) {
				let tmp = parent[nodeName];
				parent[nodeName] = [];
				parent[nodeName].push(tmp);
			}

			// Push the current object to the collection
			parent[nodeName].push(obj);
		}
	}


	convertXMLStringToDoc(str) {
		let xmlDoc = null;

		if (str && typeof str === 'string') {
			// Create a DOMParser
			let parser = new DOMParser();

			// Use it to turn your xmlString into an XMLDocument
			xmlDoc = parser.parseFromString(str, 'application/xml');
		}

		return xmlDoc;
	}


	/**
	 * Validates if an data is an XMLDocument
	 *
	 * @method isXML
	 * @param {Mixed} data
	 * @return {Boolean}
	 */
	isXML(data) {
		let documentElement = (data ? data.ownerDocument || data : 0).documentElement;
		return documentElement ? documentElement.nodeName.toLowerCase() !== 'html' : false;
	}


	/**
	 * Sends a chunk of XML to be parsed
	 *
	 * @method parse
	 * @param {XMLXtring} xml
	 * @return {JSON | Null}
	 */
	parse(xml) {
		if (xml && typeof xml === 'string') {
			xml = this.convertXMLStringToDoc(xml);
		}

		return (xml && this.isXML(xml)) ? this.parseNode({}, xml.firstChild) : null;
	}


	/**
	 * Reads through a node's attributes and assigns the values to a new object
	 *
	 * @method parseAttributes
	 * @param {XMLNode} node
	 * @return {Object}
	 */
	parseAttributes(node) {
		let attributes = node.attributes,
			obj = {};

		// If the node has attributes, assign the new object properties
		// corresponding to each attribute
		if (node.hasAttributes()) {
			for (let i = 0; i < attributes.length; i++) {
				obj[attributes[i].name] = this.parseValue(attributes[i].value);
			}
		}

		// return the new object
		return obj;
	}


	/**
	 * Rips through child nodes and parses them
	 *
	 * @method parseChildren
	 * @param {Object} parent
	 * @param {XMLNodeMap} childNodes
	 * @return none
	 */
	parseChildren(parent, childNodes) {
		// If there are child nodes...
		if (childNodes.length > 0) {
			// Loop over all the child nodes
			for (let i = 0; i < childNodes.length; i++) {
				// If the child node is a XMLNode, parse the node
				if (childNodes[i].nodeType == 1) {
					this.parseNode(parent, childNodes[i]);
				}
			}
		}
	}


	/**
	 * Converts a node into an object with properties
	 *
	 * @method parseNode
	 * @param {Object} parent
	 * @param {XMLNode} node
	 * @return {Object}
	 */
	parseNode(parent, node) {
		let nodeName = node.nodeName,
			obj = Object.assign({}, this.parseAttributes(node)),
			tmp = null;

		// If there is only one text child node, there is no need to process the children
		if (node.childNodes.length == 1 && node.childNodes[0].nodeType == 3) {
			// If the node has attributes, then the object will already have properties.
			// Add a new property 'text' with the value of the text content
			if (node.hasAttributes()) {
				obj['text'] = this.parseValue(node.childNodes[0].nodeValue);
			}
			// If there are no attributes, then the parent[nodeName] property value is
			// simply the interpreted textual content
			else {
				obj = this.parseValue(node.childNodes[0].nodeValue);
			}
		}
		// Otherwise, there are child XMLNode elements, so process them
		else {
			this.parseChildren(obj, node.childNodes);
		}

		// Once the object has been processed, add it to the parent
		this.addToParent(parent, nodeName, obj)

		// Return the parent
		return parent;
	};


	/**
	 * Interprets a value and converts it to Boolean, Number or String based on content
	 *
	 * @method parseValue
	 * @param {Mixed} val
	 * @return {Mixed}
	 */
	parseValue(val) {
		// Create a numeric value from the passed parameter
		let num = Number(val);

		// If the value is 'true' or 'false', parse it as a Boolean and return it
		if (val.toLowerCase() === 'true' || val.toLowerCase() === 'false') {
			return val.toLowerCase() == 'true';
		}

		// If the num parsed to a Number, return the numeric value
		// Else if the valuse passed has no length (an attribute without value) return null,
		// Else return the param as is
		return (isNaN(num)) ? val : (val.length == 0) ? null : num;
	}
}

