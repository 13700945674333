import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CUSTOM_VALIDATORS } from './validators/custom.validators';
import { IsInRoleDirective, DisabledByRoleDirective } from './directives/is-in-role.directive';
import { InputMaskDirective } from './directives/input-mask.directive';
import { InputNumberDirective } from './directives/input-number.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { CepPipe } from './pipes/cep.pipe';
import { CnpjCpfPipe } from './pipes/cnpjcpf.pipe';
import { FormatNumberPipe } from './pipes/formatnumber.pipe';
import { SafeHtmlPipe } from './pipes/safehtml.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { DTableDirective } from './directives/d-table.directive';
import { AutoCompleteModule } from './directives/auto-complete.directive';
import { ValidationBlockComponent } from './components/validation-block.component';
import { InputWrapperComponent } from './components/input-wrapper.component';
import { FixedHeightDirective, MinHeightDirective } from './directives/fixed-height.directive';
import { LoadingService } from './services/loading.service';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileSizePipe } from './pipes/fileSize.pipe';
import { GoogleMapsService } from './services/google-maps.service';
import { VisibleAfterDirective } from './directives/visible-after.directive';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ScrollIntoViewDirective } from './directives/scroll-into-view.directive';
import { ColspanAllDirective } from './directives/colspan-all.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Select2Directive } from './directives/select2.directive';
import { GetTituloPipe } from './pipes/gettitulo.pipe';
import { FormatDatePipe } from './pipes/formatdate.pipe';
import { GetConvenioPipe } from './pipes/getconvenio.pipe';



const CUSTOM_DIRECTIVES = [
    IsInRoleDirective, DisabledByRoleDirective, InputMaskDirective, InputNumberDirective, PhoneMaskDirective, TooltipDirective, ScrollIntoViewDirective, Select2Directive,
    DTableDirective, ValidationBlockComponent, InputWrapperComponent, FixedHeightDirective, MinHeightDirective, VisibleAfterDirective, ColspanAllDirective,
    CepPipe, CnpjCpfPipe, FormatNumberPipe, SafeHtmlPipe, PhonePipe, FileSizePipe, GetTituloPipe, FormatDatePipe, GetConvenioPipe
];

const CUSTOM_MODULES = [
    AutoCompleteModule, NgxSliderModule, NgbModule
];

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule, CUSTOM_MODULES],
    declarations: [CUSTOM_DIRECTIVES, CUSTOM_VALIDATORS],
    providers: [GoogleMapsService],
    exports: [CUSTOM_MODULES, CUSTOM_DIRECTIVES, CUSTOM_VALIDATORS]
})

export class SharedModule { }
