import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { LoadingService } from '../services/loading.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { config } from 'src/app/config';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private location: Location, private auth: AuthService, private loaderService: LoadingService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!/^(f|ht)tps?:\/\//i.test(request.url)) {
            let url = config.ROOT_API + request.url;
            if (url.indexOf('//', 6) !== -1) {
                throw 'A url contém //';
            }
            request = request.clone({ url: url });
        }
        
        this.loaderService.startLoading();
        
        if (this.auth.isAuthenticated && request.headers.get('Authorization') != null && request.headers.get('Authorization').search('Bearer') != -1) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.token}`
                }
            });
        }

        // console.log("request", request);

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            // console.log("aqui1");
            if (event instanceof HttpResponse) {
                this.loaderService.endLoading();
            }
        }, (err: any) => {
            // console.log("aqui2");
            if (err instanceof HttpErrorResponse) {
                console.log("err", err);
                if (err.status === 0) {
                    console.log('Não foi possível conectar');
                    //return;
                } else if (err.status === 401 || err.status === 403) {
                    this.auth.redirectToLogin(this.location.path());
                    // redirect to the login route
                    // or show a modal
                }
            }
            this.loaderService.endLoading();
        }));
        /*
        return next.handle(request).pipe(tap(
            (response) => {
                if (response instanceof HttpResponse) {
                    this.loaderService.endLoading();
                }
            },
            () => {
                this.loaderService.endLoading();
            }));
        */
    }
}
