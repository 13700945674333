import { Injectable } from '@angular/core';
import { config } from 'src/app/config';

import {} from "googlemaps";

@Injectable()
export class GoogleMapsService {

    private src = `https://maps.google.com/maps/api/js?key=${config.GOOGLE_API_KEY}`;

    public async loadMaps() {
        return new Promise<boolean>(resolver => {
            if (!this.isScriptLoaded) {
                let script = Object.assign(document.createElement('script'), {
                    type: 'text/javascript',
                    src: this.src,
                    onload: resolver(true)
                });
                script.async = true;
                script.defer = true;
                document.body.appendChild(script);
                //console.log('carregou script maps');
            } else {
                resolver(true);
            }
        });
    }

    public get isScriptLoaded(): boolean {
        return Array.from(document.getElementsByTagName('script')).some(html => html.src.includes(this.src));
    }

    public get hasGoogleMaps(): boolean {
        return typeof google === 'object' && typeof google.maps === 'object';
    }
}
