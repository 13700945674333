import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from 'src/app/shared/shared.module';

import { LayoutComponent } from './layout.component';
import { PartialsModule } from './_partials/_partials.module';
import { AuthGuard } from 'src/app/shared/auth/auth.guard';
import { HomeComponent } from './home.component';


const DECLARATIONS = [
    LayoutComponent, HomeComponent
];

const routes: Routes = [
    { path: '', component: LayoutComponent, canActivate: [AuthGuard] }
];

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule.forChild(routes), SharedModule, PartialsModule],
    declarations: [DECLARATIONS],
    exports: [DECLARATIONS]
})
export class LayoutModule { }
