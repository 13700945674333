import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SidebarComponent } from './sidebar.component';
import { TopnavComponent } from './topnav.component';
import { DynamicMenuComponent } from './dynamic-menu.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';


const DECLARATIONS = [
    SidebarComponent,
    TopnavComponent,
    DynamicMenuComponent
];


@NgModule({
    imports: [CommonModule, FormsModule, RouterModule, SharedModule],
    declarations: [DECLARATIONS],
    exports: [DECLARATIONS]
})
export class PartialsModule { }

