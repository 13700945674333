import { Injectable } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoadingService {
    
    private observer: Observer<boolean>;
    private count = 0;

    isLoading: Observable<boolean>;

    constructor() {
        this.isLoading = new Observable<boolean>(observer => this.observer = observer).pipe(share());
    }

    startLoading() {
        this.toggleLoading(true);
    }

    endLoading() {
        this.toggleLoading(false);
    }

    private toggleLoading(next: boolean) {
        if (this.observer) {
            if (next) {
                this.count++;
            } else {
                this.count--;
            }
            
            if (this.count > 0) {
                next = true;
            }
            
            this.observer.next(next);
        }
    }
}