import { Directive, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl, ValidatorFn } from '@angular/forms';
import { Utils } from '../utils/utils';


@Directive({
    selector: '[vEmail]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidator, multi: true }]
})
export class EmailValidator implements Validator {
    validate(c: AbstractControl): { [key: string]: any } {
        return CustomValidators.email(c);
    }
}

@Directive({
    selector: '[vCnpjCpf]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CnpjCpfValidator, multi: true }]
})
export class CnpjCpfValidator implements Validator {
    validate(c: AbstractControl): { [key: string]: any } {
        return CustomValidators.cnpjCpf(c);
    }
}

@Directive({
    selector: '[vCnpj]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CnpjValidator, multi: true }]
})
export class CnpjValidator implements Validator {
    validate(c: AbstractControl): { [key: string]: any } {
        return CustomValidators.cnpj(c);
    }
}

@Directive({
    selector: '[vCpf]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CpfValidator, multi: true }]
})
export class CpfValidator implements Validator {
    validate(c: AbstractControl): { [key: string]: any } {
        return CustomValidators.cpf(c);
    }
}

@Directive({
    selector: '[vTelefone]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PhoneValidator, multi: true }]
})
export class PhoneValidator implements Validator {
    validate(c: AbstractControl): { [key: string]: any } {
        return CustomValidators.telefone(c);
    }
}

@Directive({
    selector: '[vRemote]',
    providers: [{ provide: NG_VALIDATORS, useExisting: RemoteValidator, multi: true }]
})
export class RemoteValidator implements Validator {
    validate(c: AbstractControl): { [key: string]: any } {
        return CustomValidators.remote(c);
    }
}

@Directive({
    selector: '[vMax]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxValidator, multi: true }]
})
export class MaxValidator implements Validator, OnInit, OnChanges {
    @Input('vMax') max: number;

    private validator: ValidatorFn;

    ngOnInit() {
        this.validator = CustomValidators.max(this.max);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let key in changes) {
            if (key === 'max') {
                this.validator = CustomValidators.max(changes[key].currentValue);
            }
        }
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this.validator(c);
    }
}

@Directive({
    selector: '[vMin]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinValidator, multi: true }]
})
export class MinValidator implements Validator, OnInit, OnChanges {
    @Input('vMin') min: number;

    private validator: ValidatorFn;

    ngOnInit() {
        this.validator = CustomValidators.min(this.min);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (let key in changes) {
            if (key === 'min') {
                this.validator = CustomValidators.min(changes[key].currentValue);
            }
        }
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this.validator(c);
    }
}

export class CustomValidators {
    static email(c: AbstractControl): { [key: string]: any } {
        if (c.value && c.value !== '') {
            if (!Utils.validateEmail(c.value)) {
                return { 'email': true };
            }
        }
    }

    static cnpjCpf(c: AbstractControl): { [key: string]: any } {
        if (c.value && c.value !== '') {
            if (!Utils.validateCnpj(c.value) && !Utils.validateCpf(c.value)) {
                return { 'cnpjCpf': true };
            }
        }
    }

    static cnpj(c: AbstractControl): { [key: string]: any } {
        if (c.value && c.value !== '') {
            if (!Utils.validateCnpj(c.value)) {
                return { 'cnpj': true };
            }
        }
    }

    static cpf(c: AbstractControl): { [key: string]: any } {
        if (c.value && c.value !== '') {
            if (!Utils.validateCpf(c.value)) {
                return { 'cpf': true };
            }
        }
    }

    static telefone(c: AbstractControl): { [key: string]: any } {
        if (c.value && c.value !== '') {
            if (!Utils.validateTelefone(c.value)) {
                return { 'telefone': true };
            }
        }
    }

    static remote(c: AbstractControl): { [key: string]: any } {
        if (c.value && c.value !== '') {
            if (c.getError('remote')) {
                return { 'remote': c.getError('remote') };
            }
        }
    }

    static max(max: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: any } => {
            let v: number = c.value;
            let s = '';
            if (max) {
                s = max.toString().replace('.', ',');
            }
            return v <= max ? null : { 'max': { 'value': s } };
        };
    }

    static min(min: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: any } => {
            let v: number = c.value;
            let s = '';
            if (min) {
                s = min.toString().replace('.', ',');
            }
            return v >= min ? null : { 'min': { 'value': s } };
        };
    }


}

export const CUSTOM_VALIDATORS = [
    CnpjCpfValidator, CnpjValidator, CpfValidator, EmailValidator, PhoneValidator, RemoteValidator, MaxValidator, MinValidator
];