import { Component } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    constructor() {
        const theme = localStorage.getItem('theme');
        if (theme == 'light') {
            // document.getElementById('csstheme').setAttribute('href', 'assets/css/theme-dark.min.css');
            document.getElementById('csstheme').setAttribute('href', 'assets/css/theme.min.css');
            document.getElementById('cssthemecustom').setAttribute('href', 'assets/css/theme.css');
        } else {
            // document.getElementById('csstheme').setAttribute('href', 'assets/css/theme.min.css');
            document.getElementById('csstheme').setAttribute('href', 'assets/css/theme-dark.min.css');
            document.getElementById('cssthemecustom').setAttribute('href', 'assets/css/theme-dark.css');
        }
    }

}
