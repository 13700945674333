import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Utils, XmlToJson } from '../shared/utils/utils';

@Component({
    selector: 'app-home',
    templateUrl: 'home.component.html'
})

export class HomeComponent implements OnInit {
    version: string = "";
    roles = '';

    constructor(private http: HttpClient) { }

    ngOnInit() {
        this.version = sessionStorage.getItem("version") != null ? sessionStorage.getItem("version") : localStorage.getItem("version");
        this.roles = sessionStorage.getItem('app_roles');
        // console.log("Roles:",this.roles);
        // console.log(this.isInRole("cto_excluir"));
    }

    isInRole(role)
    {
        return this.roles.indexOf(role) > 0;
    }

    post() {
        const headers = new HttpHeaders({ 'Content-Type': 'text/xml'})
        //headers.set('Accept', 'text/xml');
        //headers.set('Content-Type', 'text/xml');

        const xml = `<?xml version="1.0" encoding="utf-8"?>
        <soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
          <soap12:Body>
            <IsUserOnline xmlns="http://tempuri.org/">
              <username>stona</username>
            </IsUserOnline>
          </soap12:Body>
        </soap12:Envelope>`;

        this.http.post('http://localhost:50347/CiscoWebService.asmx?op=IsUserOnline', xml, {
            headers: headers,
            responseType: 'text'
        }).subscribe(n => {
            const xml = new XmlToJson(n);

            const a = Utils.findNestedObj(xml, 'diffgr:id', 'registro1');

            if (a) {
                console.log(a.address);
                console.log(a.link);
            }

        });
    }

}

