import { Directive, ElementRef, Input, HostListener, NgZone, ChangeDetectorRef, OnDestroy, AfterViewInit, EventEmitter } from '@angular/core';
import { Utils } from 'src/app/shared/utils/utils';

@Directive({
    selector: '[dTable]',
    exportAs: 'dTable'
})
export class DTableDirective {

    private fields = {};
    private lastSelectedIndex: number;
    private sortBy = '';
    private sortDir: any;
    private filteredData: any[];
    private checkAll: HTMLInputElement;

    @Input() sortDefaultAsc: string;
    @Input() sortDefaultDesc: string;
    @Input() changeSelection: EventEmitter<any>;
    @Input() disableClick: boolean = false;


    private _selectedItens: any[] = [];
    get selectedItens() {
        return this._selectedItens;
    }

    private _data: any[];
    @Input('dTable') set data(value) {
        if (!this.checkAll) {

            // let $itens = $(this.el.nativeElement).find('input[type=checkbox]');
            // this.checkAll = $itens.eq(0);

            // $(this.checkAll).on('click', (event) => {
            //     this.clickCheckAll(event);
            // });

            this.checkAll = this.el.nativeElement.querySelector('input[type=checkbox]');
            this.checkAll?.addEventListener('click', (event) => {
                this.clickCheckAll(event);
            });
        } else {
            this.checkAll.checked = false;
        }

        this._data = value;
        this._selectedItens = [];
        this.executeSortDefault();
    }

    get data() {
        return this.filteredData ? this.filteredData : this._data;
    }

    constructor(public el: ElementRef, private zone: NgZone, private cdr: ChangeDetectorRef) { }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        if (this.disableClick) {
            return;
        }

        this.zone.runOutsideAngular(() => {

            const target = (event.target || event.srcElement || event.currentTarget) as Element;

            let checkBox = target.parentElement.querySelector('[type="checkbox"]') as HTMLInputElement;

            let rowIndex = 0;

            if (target.localName === 'input') {
                checkBox = target as HTMLInputElement;;
                const tr = target.parentElement.parentElement.parentElement as HTMLTableRowElement;
                rowIndex = tr.rowIndex;
            }

            // if (!(checkBox.type && checkBox.type === 'checkbox')) {
            //     return false;
            // }

            if (!rowIndex) {
                /**
                 * Quando clica na linha zero indica que é o checkAll.
                 * O Evento de click no checkAll é tratado via JQuery em
                 * função da diretiva fixed-header que cria um cabeçalho extra e o @HostListener
                 * deixa de funcionar neste caso
                 */
                return true;
            }

            const itens = this.el.nativeElement.querySelectorAll('input[type=checkbox]') as NodeListOf<HTMLInputElement>;

            if (event.shiftKey) {

                let a = this.lastSelectedIndex;
                let b = rowIndex;

                let colA = this.data[a - 1];

                if (a >= b) {
                    a = rowIndex;
                    b = this.lastSelectedIndex;
                }

                for (let index = a; index <= b; index++) {
                    let item = this.data[index - 1];
                    if (item) {
                        item.selected = colA.selected;
                        //$itens.eq(index).prop('checked', colA.selected);
                        itens.item(index).checked = colA.selected;
                    }
                }
            } else {
                this.data[rowIndex - 1].selected = !this.data[rowIndex - 1].selected;
                itens.item(rowIndex).checked = this.data[rowIndex - 1].selected;
            }
            this.lastSelectedIndex = rowIndex;

            this.updateSelectedList();

            window.getSelection().removeAllRanges();

            if (!(checkBox.type && checkBox.type === 'checkbox')) {
                event.stopPropagation();
                event.preventDefault();
            }
            if (this.changeSelection) {
                this.changeSelection.emit();
            }
            return false;
        });

    }

    clickCheckAll = (event) => {
        this.selectAll(event.target.checked);

        this.cdr.detectChanges();
        if (this.changeSelection) {
            this.changeSelection.emit();
        }
        return false;
    }

    filter(s, uuid: string, fields: string[]) {
        this.filteredData = null;
        if (!s) {
            return;
        }

        let val = s.toString();
        val = Utils.removeAcentos(val);

        let aux = [];
        fields.forEach(c => {
            let x = this._data.filter(n => {
                let field = Utils.resolve(c, n) || '';
                field = field.toString().toLowerCase();
                field = Utils.removeAcentos(field);

                return (field.indexOf(val.toLowerCase()) > -1);
            }).map(n => Utils.resolve(uuid, n));
            aux = [...aux, ...x];
        });
        this.filteredData = this._data.filter(n => aux.indexOf(Utils.resolve(uuid, n)) > -1);

        this.updateSelectedList();
        this.cdr.detectChanges();
    }

    resetFilter() {
        this.filteredData = null;
    }

    sort(field, dir?: sortDirection) {
        if (!Utils.resolve(field + '.sort', this.fields)) {
            this.assign(this.fields, field.split('.'), { sort: dir || 'ASC' });
        }

        let f = Utils.resolve(field, this.fields);

        this.sortDir = f.sort;

        if (f.sort === 'ASC') {
            Utils.orderBy(this.data, field);
            f.sort = 'DESC';
        } else {
            Utils.orderByDescending(this.data, field);
            f.sort = 'ASC';
        }

        this.sortBy = field;
    }

    updateSelectedList() {
        this._selectedItens = [];
        let dataSelect = this.filteredData;
        if (!dataSelect) {
            dataSelect = this.data;
        }
        if (this.data) {
            for (let i = 0; i < dataSelect.length; i++) {
                let element = dataSelect[i];
                if (element.selected) {
                    this._selectedItens.push(element);
                }
            }
        }
    }

    collapseAll(data?: any[], field?) {
        if (!data) {
            data = this._data;
        }
        if (!field) {
            field = 'expanded';
        }
        data.filter(n => n[field]).forEach(n => n[field] = false);
    }

    expandAll(data?: any[], field?) {
        if (!data) {
            data = this._data;
        }
        if (!field) {
            field = 'expanded';
        }
        data.forEach(n => n[field] = true);
    }

    private executeSortDefault() {
        if (this.sortDefaultAsc && this.sortDefaultDesc) {
            throw new Error('Só pode haver um d-table-sort-default-asc ou d-table-sort-default-desc');
        }

        if (this.sortBy && this.sortDir) {
            if (this.sortDir === 'ASC') {
                this.sortDefaultAsc = this.sortBy;
                this.sortDefaultDesc = undefined;
            }
            if (this.sortDir === 'DESC') {
                this.sortDefaultDesc = this.sortBy;
                this.sortDefaultAsc = undefined;
            }
        }

        if (this.sortDefaultAsc || this.sortDefaultDesc) {
            let times = 0;
            let i1 = setInterval(() => {
                times++;
                if ((this._data) && (this._data.length > 0)) {
                    this.fields = {};

                    let dir: any = 'ASC';
                    if (this.sortDefaultDesc) {
                        dir = 'DESC';
                    }
                    this.sort(this.sortDefaultAsc || this.sortDefaultDesc, dir);
                    clearInterval(i1);
                } else if (times > 20) {
                    clearInterval(i1);
                }
            }, 25);
        }
    }

    sortIcon(field) {
        let f = Utils.resolve(field, this.fields);

        if (field !== this.sortBy || !f || !f.sort) {
            return '<i class="fa fa-sort text-gray"></i>';
        }

        if (f.sort === 'ASC') {
            return '<i class="fa fa-sort-desc"></i>';
        } else if (f.sort === 'DESC') {
            return '<i class="fa fa-sort-asc"></i>';
        } else {
            return '<i class="fa fa-sort text-gray"></i>';
        }
    }

    sum(field: string): number {
        return Utils.sum(this.data, field);
    }

    selectAll = (value) => {
        const itens = this.el.nativeElement.querySelectorAll('input[type=checkbox]') as NodeListOf<HTMLInputElement>;
        itens.forEach(n => {
            
            if(n.hasAttribute("tentativas"))
            {
                // console.log("tentativa: " + n.getAttribute("tentativas"));
                if(sessionStorage.getItem("app_roles").indexOf("crt_autorizarcobrancarepetida") > 0 || Number(n.getAttribute("tentativas")) <= 1){
                    n.checked = value;
                }else{
                    n.checked = false;
                }
                // console.log(n.checked);
            }else{
                n.checked = value;
            }
            
        });
        // console.log(itens);
        //$(this.el.nativeElement).find('input[type=checkbox]').prop('checked', value);
        //$(this.checkAll).prop('checked', value);
        
        // Condição inserida para a área de Cobrança -> Cartão de Crédito / Caso o número de tentativas seja maior do que 2, não seleciona o título para efetuar a cobrança.
        this.data.forEach(n => {
                // console.log(n);
                if(n.tentativa != 'undefined' && n.tentativa >= 1 && sessionStorage.getItem("app_roles").indexOf("crt_autorizarcobrancarepetida") <= 0){
                    //console.log("Não selecionadoo!");
                    n.selected = false;
                }
                else if(sessionStorage.getItem("app_roles").indexOf("crt_autorizarcobrancarepetida") > 0){
                    // console.log("acessou o privilegio");
                    n.selected = value;
                }
                else{
                    // console.log("Define: " + value);
                    n.selected = value;
                }
        });
        this.updateSelectedList();
    }

    selectBy(field, itens: any[], classHtml = 'shouldSelect') {
        setTimeout(() => {

            //$(this.el.nativeElement).find('input[type=checkbox].' + classHtml).prop('checked', true);

            const itens = this.el.nativeElement.querySelectorAll('input[type=checkbox.' + classHtml) as NodeListOf<HTMLInputElement>;
            itens.forEach(n => {
                n.checked = true;
            });


        }, 0);
        itens.forEach(item => {
            let el = this.data.find(n => Utils.resolve(field, n) === item);
            el.selected = true;
        });
        this.updateSelectedList();
    }

    deselectBy(field, itens: any[], classHtml = 'shouldSelect') {
        setTimeout(() => {
            //$(this.el.nativeElement).find('input[type=checkbox].' + classHtml).prop('checked', false);
            const itens = this.el.nativeElement.querySelectorAll('input[type=checkbox.' + classHtml) as NodeListOf<HTMLInputElement>;
            itens.forEach(n => {
                n.checked = false;
            });

        }, 0);
        itens.forEach(item => {
            let el = this.data.find(n => Utils.resolve(field, n) === item);
            el.selected = false;
        });
        this.updateSelectedList();
    }

    private assign(obj, keyPath, value) {
        let lastKeyIndex = keyPath.length - 1;
        for (let i = 0; i < lastKeyIndex; ++i) {
            let key = keyPath[i];
            if (!(key in obj)) {
                obj[key] = {};
            }
            obj = obj[key];
        }
        obj[keyPath[lastKeyIndex]] = value;
    }


}

export type sortDirection = 'ASC' | 'DESC';
