import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../utils/utils';

@Pipe({
    name: 'gettitulo'
})

export class GetTituloPipe implements PipeTransform {

    transform(value: any): any {
        value = value || '0';
        value = value.substring(10,value.length);
        value = value.replace(/^0+/, '');       // remove zeros a esquerda.
        
        return value;
    }

}
